/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import {
  Badge,
  Box,
  CircularProgress,
  Grid,
  Grow,
  ToggleButton,
  Typography,
} from '@mui/material';
import { Fragment, useEffect, useMemo, useState } from 'react';

import { EvaluationType, PropertyType } from '../../../api/enumerations';
import { ElementData } from '../../../api/sample/types';
import { WorkOrderData } from '../../../api/workOrders/types';
import { ExportSample } from '../../../components/ExportSample';
import ElementsMap from '../../../components/Maps/ElementsMap';
import { CardPagination } from '../../../components/Sections/Sample/CardPagination';
import { DashboardIcon } from '../../../components/Sections/Sample/DashboardIcon';
import { UpButton } from '../../../components/Sections/Sample/UpButton';
import {
  IconAddMS,
  IconViewArrayMS,
  IconViewListMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/sampleCreation';
import useSample from '../hooks';
import { ElementCard } from './ElementCard';
import {
  CancelSelectiingButtonsElements,
  DashboardContainer,
  LevelButton,
  MissingBox,
  SampleText,
  SectionBox,
  SelectiingButtonsElements,
  StyledDivider,
  StyledToggleButtonGroup,
  StyledTooltip,
} from './styles';

type LatLngLiteral = google.maps.LatLngLiteral;

interface SampleProps {
  propertyData: WorkOrderData;
  sampleData: ElementData[];
  completeElements: ElementData[];
  incompleteElements: ElementData[];
  pinPlace: LatLngLiteral | undefined;
  showMap: boolean;
  updateSample: () => Promise<void>;
  minElements: string;
  disapprovedElements: number[];
  setDisapprovedElements: (value: number[]) => void;
  page: number;
  setPage: (value: number) => void;
  setLastElementEdited: (value: number | undefined) => void;
}

export function Sample({
  propertyData,
  sampleData,
  completeElements,
  incompleteElements,
  pinPlace,
  showMap,
  updateSample,
  minElements,
  disapprovedElements,
  setDisapprovedElements,
  page,
  setPage,
  setLastElementEdited,
}: SampleProps): JSX.Element {
  const {
    view,
    handleView,
    complete,
    setComplete,
    incomplete,
    setIncomplete,
    deactivateFilters,
    osId,
  } = useSample();
  const [activatesElementSelection, setActivatesElementSelection] =
    useState(false);
  const [selectedIcons, setSelectedIcons] = useState<number[]>([]);

  const dashboardIcons = useMemo(() => {
    return sampleData.map((element, ind) => {
      const samplePageId = ind + 1;
      const isSelected = selectedIcons.includes(samplePageId);
      const isApproved =
        (element?.description?.level === 1 && element.approved) ||
        (!element.description.level && element.approved);

      const handleIconClick = (): void => {
        if (activatesElementSelection) {
          setSelectedIcons((prev) =>
            isSelected
              ? prev.filter((id) => id !== samplePageId)
              : [...prev, samplePageId]
          );
        } else {
          setPage(samplePageId);
        }
      };
      return (
        <Fragment key={element.id}>
          <Box
            sx={{
              position: 'relative',
              display: 'inline-block',
              opacity: activatesElementSelection ? 0.7 : 1,
            }}
          >
            <Badge
              color="primary"
              badgeContent={
                activatesElementSelection ? (isSelected ? '✓' : '') : null
              }
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: activatesElementSelection
                    ? 'white'
                    : 'transparent',
                  color: isSelected ? '#28C40A' : 'transparent',
                  border: '2px solid #CC34FF',
                  width: '22px',
                  fontSize: '16px',
                  fontWeight: 600,
                  paddingTop: '1px',
                  height: '22px',
                  top: '4px',
                  right: '5px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
            >
              <DashboardIcon
                page={samplePageId}
                setPage={handleIconClick}
                sampleId={samplePageId}
                alert={!isApproved}
              />
            </Badge>
          </Box>
        </Fragment>
      );
    });
  }, [sampleData, activatesElementSelection, selectedIcons]);

  useEffect(() => {
    if (view === 'list') {
      document.getElementById(`${page}`)?.scrollIntoView();
    }
  }, [page, view]);

  const handleLastEditedPage = (): void => setLastElementEdited(page);

  const pageValue = (element?: ElementData): number => {
    if (element) {
      return sampleData.indexOf(element) + 1;
    }
    if (complete) {
      return sampleData.indexOf(completeElements[page - 1]) + 1;
    }
    if (incomplete) {
      return sampleData.indexOf(incompleteElements[page - 1]) + 1;
    }
    return page;
  };

  const handleMinElements = (): string => {
    if (
      propertyData.evaluation_type === EvaluationType.AUTOINFERENCES ||
      propertyData.evaluation_type === EvaluationType.SIMPINFERENCES
    ) {
      if (propertyData.real_estate_kind === PropertyType.APARTMENT) {
        return Constants.minInfeApartment;
      }
      return Constants.minInfeHouse;
    }
    return Constants.minFactors;
  };

  return (
    <>
      {showMap && (
        <SampleText id="sample-length">
          {`A amostra possui ${sampleData.length} elementos`}
        </SampleText>
      )}
      {sampleData.length > 0 && (
        <>
          {pinPlace &&
            (showMap ? (
              <ElementsMap
                sampleData={sampleData}
                pinPlace={pinPlace}
                deactivateFilters={deactivateFilters}
                page={pageValue()}
                setPage={setPage}
                sampleCreationStatus
              />
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            ))}
          <SectionBox id="top">
            <StyledToggleButtonGroup
              value={view}
              exclusive
              onChange={handleView}
              aria-label="visualização"
            >
              <ToggleButton value="card" aria-label="individual">
                {IconViewArrayMS}
              </ToggleButton>
              <ToggleButton value="list" aria-label="lista">
                {IconViewListMS}
              </ToggleButton>
            </StyledToggleButtonGroup>
            <StyledDivider orientation="vertical" flexItem />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box>
                <LevelButton
                  complete="true"
                  value="complete"
                  selected={complete}
                  onChange={() => {
                    setPage(1);
                    setIncomplete(false);
                    setComplete(!complete);
                  }}
                >
                  {Constants.complete}
                </LevelButton>
                <LevelButton
                  value="incomplete"
                  selected={incomplete}
                  onChange={() => {
                    setPage(1);
                    setComplete(false);
                    setIncomplete(!incomplete);
                  }}
                >
                  {Constants.incomplete}
                </LevelButton>
              </Box>
              <ExportSample
                responsive
                osId={osId}
                title={Constants.exportButtonTitle}
              />
            </Box>
          </SectionBox>
          <Typography fontSize={18}>{handleMinElements()}</Typography>
          {!complete && !incomplete && (
            <>
              <DashboardContainer id="dashboard">
                {dashboardIcons}
                {minElements && (
                  <StyledTooltip title="máximo de elementos" placement="top">
                    <MissingBox>
                      {minElements}
                      {IconAddMS}
                    </MissingBox>
                  </StyledTooltip>
                )}
                <Grid item container justifyContent="end">
                  <Grow
                    in={activatesElementSelection}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(activatesElementSelection ? { timeout: 1000 } : {})}
                  >
                    <CancelSelectiingButtonsElements
                      onClick={() => {
                        setActivatesElementSelection(false);
                        setSelectedIcons([]);
                      }}
                    >
                      {Constants.cancel}
                    </CancelSelectiingButtonsElements>
                  </Grow>
                  <SelectiingButtonsElements
                    className={activatesElementSelection ? 'selected' : ''}
                    onClick={() => setActivatesElementSelection(true)}
                  >
                    {activatesElementSelection
                      ? Constants.elementsSelectedDeletion
                      : Constants.selectingElements}
                  </SelectiingButtonsElements>
                </Grid>
              </DashboardContainer>{' '}
              {view === 'card' ? (
                <>
                  <CardPagination
                    sampleLength={sampleData.length}
                    page={page}
                    setPage={setPage}
                  />
                  <ElementCard
                    sampleData={sampleData}
                    updateSample={updateSample}
                    disapprovedElements={disapprovedElements}
                    setDisapprovedElements={setDisapprovedElements}
                    sampleId={pageValue()}
                    elementData={sampleData[page - 1]}
                    propertyData={propertyData}
                    handleLastEditedPage={handleLastEditedPage}
                    setPage={setPage}
                    page={page}
                    goToLastPage={
                      sampleData.length > 1 && sampleData.length === page
                    }
                  />
                </>
              ) : (
                <Box>
                  {sampleData.map((element, idx) => (
                    <Box id={`${idx + 1}`} key={element.id}>
                      <ElementCard
                        sampleData={sampleData}
                        updateSample={updateSample}
                        disapprovedElements={disapprovedElements}
                        setDisapprovedElements={setDisapprovedElements}
                        sampleId={pageValue(element)}
                        elementData={element}
                        propertyData={propertyData}
                        handleLastEditedPage={handleLastEditedPage}
                        setPage={setPage}
                        page={page}
                        goToLastPage={sampleData.length > 1}
                      />
                    </Box>
                  ))}
                  <UpButton />
                </Box>
              )}
            </>
          )}
          {complete && (
            <>
              <DashboardContainer>
                {completeElements?.map((element, ind) => (
                  <DashboardIcon
                    key={element.id}
                    page={ind + 1}
                    setPage={setPage}
                    sampleId={sampleData.indexOf(element) + 1}
                    alert={false}
                  />
                ))}
                {minElements && (
                  <StyledTooltip title="máximo de elementos" placement="top">
                    <MissingBox>
                      {minElements}
                      {IconAddMS}
                    </MissingBox>
                  </StyledTooltip>
                )}
              </DashboardContainer>
              {!!completeElements && completeElements.length > 0 && (
                <>
                  {view === 'card' ? (
                    <>
                      <CardPagination
                        sampleLength={completeElements.length}
                        page={page}
                        setPage={setPage}
                      />
                      <ElementCard
                        sampleData={sampleData}
                        updateSample={updateSample}
                        disapprovedElements={disapprovedElements}
                        setDisapprovedElements={setDisapprovedElements}
                        sampleId={pageValue()}
                        elementData={completeElements[page - 1]}
                        propertyData={propertyData}
                        handleLastEditedPage={handleLastEditedPage}
                        setPage={setPage}
                        page={page}
                        goToLastPage={
                          completeElements.length > 1 &&
                          completeElements.length === page
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Box>
                        {completeElements.map((element, idx) => (
                          <Box id={`${idx + 1}`} key={element.id}>
                            <ElementCard
                              sampleData={sampleData}
                              updateSample={updateSample}
                              disapprovedElements={disapprovedElements}
                              setDisapprovedElements={setDisapprovedElements}
                              sampleId={pageValue(element)}
                              elementData={element}
                              propertyData={propertyData}
                              handleLastEditedPage={handleLastEditedPage}
                              setPage={setPage}
                              page={page}
                              goToLastPage={sampleData.length > 1}
                            />
                          </Box>
                        ))}
                        <UpButton />
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {incomplete && (
            <>
              <DashboardContainer>
                {incompleteElements?.map((element, ind) => (
                  <DashboardIcon
                    key={element.id}
                    page={ind + 1}
                    setPage={setPage}
                    sampleId={sampleData.indexOf(element) + 1}
                    alert
                  />
                ))}
                {minElements && (
                  <StyledTooltip title="máximo de elementos" placement="top">
                    <MissingBox>
                      {minElements}
                      {IconAddMS}
                    </MissingBox>
                  </StyledTooltip>
                )}
              </DashboardContainer>
              {!!incompleteElements && incompleteElements.length > 0 && (
                <>
                  {view === 'card' ? (
                    <>
                      <CardPagination
                        sampleLength={incompleteElements.length}
                        page={page}
                        setPage={setPage}
                      />
                      <ElementCard
                        sampleData={sampleData}
                        updateSample={updateSample}
                        disapprovedElements={disapprovedElements}
                        setDisapprovedElements={setDisapprovedElements}
                        sampleId={pageValue()}
                        elementData={incompleteElements[page - 1]}
                        propertyData={propertyData}
                        handleLastEditedPage={handleLastEditedPage}
                        setPage={setPage}
                        page={page}
                        deactivateFilters={deactivateFilters}
                        goToLastPage={
                          incompleteElements.length > 1 &&
                          incompleteElements.length === page
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Box>
                        {incompleteElements.map((element, idx) => (
                          <Box id={`${idx + 1}`} key={element.id}>
                            <ElementCard
                              sampleData={sampleData}
                              updateSample={updateSample}
                              disapprovedElements={disapprovedElements}
                              setDisapprovedElements={setDisapprovedElements}
                              sampleId={pageValue(element)}
                              elementData={element}
                              propertyData={propertyData}
                              handleLastEditedPage={handleLastEditedPage}
                              setPage={setPage}
                              page={page}
                              deactivateFilters={deactivateFilters}
                              goToLastPage={sampleData.length > 1}
                            />
                          </Box>
                        ))}
                        <UpButton />
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
