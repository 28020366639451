import { Box, AccordionDetails } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

import { IconExpandMoreMS } from '../../../constants/icons';

export const ContainerBox = styled(Box)(() => ({
  margin: '40px 0',
}));

export const ObservationsContainer = styled(Box)(({ theme }) => ({
  maxHeight: 400,
  border: '1px solid #DCC7E6',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export const ObservationAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: '1px solid #DCC7E6',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

export const ObservationSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={IconExpandMoreMS} {...props} />
))(({ theme }) => ({
  padding: '12px',
  backgroundColor: '#F2F2F2',
  flexDirection: 'row-reverse',
  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    transform: 'rotate(-90deg)',
    color: theme.palette.primary.main,
  },
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: 'rotate(0deg)',
    },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
  pointerEvents: 'none',
  span: {
    pointerEvents: 'auto',
  },
}));

export const ObservationInfo = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));

export const TextBox = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
}));

export const ActionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '21px',
  span: {
    color: theme.palette.primary.main,
    '&.hover': {
      fontWeight: '600',
      color: 'red',
    },
  },
}));

export const ObservationText = styled(AccordionDetails)(() => ({
  backgroundColor: '#F2F2F2',
}));
