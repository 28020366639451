/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { driver } from 'driver.js';
import { ChangeEvent, useEffect, useState } from 'react';

import {
  PropertyType,
  StepTour,
  convertClientKind,
  convertEvaluationType,
  convertPropertyType,
} from '../../api/enumerations';
import {
  CustomPatternFormat,
  CustomTextField,
  NumericTextField,
  SelectTextField,
} from '../../components/CustomInput';
import { AttachFilesDialog } from '../../components/Dialog/AttachFilesDialog';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog';
import { MessagesDialog } from '../../components/Dialog/Messages';
import { PeptDialog } from '../../components/Dialog/PeptDialog';
import StandardMap from '../../components/Maps/StandardMap';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { EditWorkOrder } from '../../components/Sections/EditWorkOrder';
import { Observations } from '../../components/Sections/Observations';
import { WorkOrderProgressBar } from '../../components/Sections/ProgressBar';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { Title } from '../../components/Sections/Title';
import { WorkOrderFile } from '../../components/Sections/WorkOrderFile';
import { ToogleAccordion } from '../../components/ToogleAccordion';
import {
  BoxContainer,
  CancelOsBox,
  FlexSpaceBetweenBox,
  LoadingBox,
  SectionBox,
  SubmitBox,
} from '../../components/UI/Box';
import {
  BackButton,
  CancelOSButton,
  FilledButton,
  RoundedButton,
} from '../../components/UI/Button';
import { GridContainer } from '../../components/UI/Grid';
import {
  InputTitle,
  SectionTitle,
  StyledInput,
} from '../../components/UI/Typography';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconCancelMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPersonMS,
} from '../../constants/icons';
import { Constants } from '../../constants/schedule';
import {
  selectConcept,
  selectConservation,
  selectPropertyUse,
  selectRegistrationUf,
  selectReportFunction,
  selectReportGoal,
  selectStandard,
} from '../../constants/selectOptions';
import { formatPhone } from '../../helpers';
import { driverConfig } from '../../helpers/driver/config';
import { scheduleSteps } from '../../helpers/driver/steps';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import useGeneral from '../../hooks/useGeneral';
import { useTour } from '../../hooks/useTour';
import { Calendar } from './Calendar';
import useSchedule from './hooks';
import {
  BoldTypography,
  CoordGrid,
  FlexBox,
  PatternFormatStyle,
  StyledTextField,
} from './styles';

export default function Schedule(): JSX.Element {
  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const [tourHasStarted, setTourHasStarted] = useState(false);

  const { navigateHome, osId } = useGeneral();
  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandAll,
    setExpandAll,
    toogleAccordions,
  } = useAccordion();
  const {
    propertyData,
    engineer,
    setEngineer,
    usersData,
    responsibleName,
    setResponsibleName,
    contact,
    setContact,
    date,
    setDate,
    startTime,
    endTime,
    hasSchedule,
    scheduledData,
    address,
    setAddress,
    addressNumber,
    setAddressNumber,
    age,
    setAge,
    buildingStandard,
    setBuildingStandard,
    builtArea,
    setBuiltArea,
    cep,
    setCep,
    city,
    setCity,
    client,
    setClient,
    complement,
    setComplement,
    concept,
    setConcept,
    conservation,
    setConservation,
    district,
    setDistrict,
    goal,
    setGoal,
    judicialDistrict,
    setJudicialDistrict,
    parkingLots,
    setParkingLots,
    propertyFunction,
    setPropertyFunction,
    propertyUse,
    setPropertyUse,
    downloadIptu,
    downloadRegister,
    registerNumber,
    setRegisterNumber,
    registrationUf,
    setRegistrationUf,
    propertyRooms,
    setPropertyRooms,
    solicitor,
    setSolicitor,
    suites,
    setSuites,
    toilets,
    setToilets,
    totalArea,
    setTotalArea,
    uf,
    setUf,
    zone,
    setZone,
    registerFileName,
    iptuFileName,
    searchMap,
    setSearchMap,
    setIsAddressEdited,
    pinPlace,
    setPinPlace,
    enableEditing,
    setEnableEditing,
    today,
    getEngineerEvents,
    handleStartTime,
    handleEndTime,
    handleSchedule,
    handleScheduleExist,
    handleSubmit,
    verifyCep,
    handleFileUpload,
    handleDeleteSchedule,
    submitLoading,
    loadingPage,
    loadingApprove,
    setLoadingApprove,
    completeScheduling,
  } = useSchedule();

  const {
    tourCompletion,
    setTourCompletion,
    setTutorialStep,
    isTourOn,
    setTourOn,
    setToBeContinued,
    setTourSelection,
    driveIsActive,
    setDriveIsActive,
    tourSelection,
  } = useTour();

  const driverObj = driver({
    ...driverConfig,
    steps: scheduleSteps,
    onNextClick: () => {
      if (driverObj.getActiveIndex() === 1 && engineer === 0) {
        driverObj.destroy();
      }
      if (driverObj.isLastStep()) {
        setTourCompletion({
          ...tourCompletion,
          schedule: { complete: true },
          lastStepSeen: StepTour.SCHEDULEFINISH,
        });
        setTutorialStep(StepTour.SCHEDULEFINISH);
        setToBeContinued(false);
        setTourSelection(false);
        setDriveIsActive(false);
        setTourOn(true);
      }
      driverObj.moveNext();
    },
    onCloseClick: () => {
      setToBeContinued(false);
      setTourSelection(false);
      setDriveIsActive(false);
      driverObj.destroy();
    },
    onDestroyStarted: () => {
      setToBeContinued(false);
      setTourSelection(false);
      setDriveIsActive(false);
      driverObj.destroy();
    },
  });

  useEffect(() => {
    if (
      !tourCompletion.schedule.complete &&
      !tourCompletion.skipTour.schedule
    ) {
      setTutorialStep(StepTour.SCHEDULESTART);
      if (!driveIsActive && !tourHasStarted) {
        setTourOn(true);
        setTourHasStarted(true);
      } else if (!isTourOn && driveIsActive) {
        setExpandFour(true);
        driverObj.drive();
      }
    } else if (tourSelection && driveIsActive && !isTourOn) {
      setTutorialStep(StepTour.SCHEDULESTART);
      setExpandFour(true);
      setTimeout(() => {
        driverObj.drive();
      }, 250);
    }
  }, [tourSelection, driveIsActive, isTourOn]);

  useEffect(() => {
    if (engineer !== 0 && !driverObj.isActive()) {
      if (driveIsActive) {
        setTimeout(() => {
          driverObj.moveTo(2);
        }, 1000);
      }
    }
  }, [engineer]);

  useEffect(() => {
    if (expandOne && expandTwo && expandThree && expandFour) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [expandOne, expandTwo, expandThree, expandFour]);

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer component="form" id="schedule" onSubmit={handleSubmit}>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.schedule}
          createdAt={propertyData?.created_at}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <FlexBox>
                {!isBank && (
                  <CancelOsBox>
                    <ConfirmationDialog
                      id="cancel-btn"
                      text={Constants.cancelOsText}
                      button={
                        <CancelOSButton>
                          {IconCancelMS}
                          {Constants.cancelOs}
                        </CancelOSButton>
                      }
                      model="error"
                      modalCallback={handleCancelWorkOrder}
                    />
                  </CancelOsBox>
                )}
                <EditWorkOrder
                  edit={enableEditing}
                  setEdit={setEnableEditing}
                />
              </FlexBox>
              <FlexSpaceBetweenBox gap="10px">
                {isBank && propertyData?.worker_company_id !== null && (
                  <MessagesDialog />
                )}
                <AttachFilesDialog propertyData={propertyData} osId={osId} />
              </FlexSpaceBetweenBox>
            </FlexSpaceBetweenBox>
            <WorkOrderProgressBar complete>
              <>
                {enableEditing ? (
                  <SectionBox>
                    <SectionTitle mb="20px">
                      {IconPersonMS}
                      {Constants.clientData}
                    </SectionTitle>
                    <Grid container spacing={4}>
                      <Grid item xs={6} xl={4}>
                        <CustomTextField
                          required
                          id="client"
                          label="proprietário do imóvel"
                          value={client}
                          setValue={setClient}
                        />
                      </Grid>
                      <Grid item xs={6} xl={4}>
                        <CustomTextField
                          id="solicitor"
                          label="solicitante"
                          value={solicitor}
                          setValue={setSolicitor}
                        />
                      </Grid>
                      <Grid item xs={6} xl={4}>
                        <SelectTextField
                          id="os-goal"
                          label="objetivo"
                          value={goal}
                          setValue={setGoal}
                          selectOptions={selectReportGoal()}
                        />
                      </Grid>
                      <Grid item xs={6} xl={3}>
                        <InputTitle>{Constants.evaluationType}</InputTitle>
                        <StyledInput>
                          {propertyData &&
                            convertEvaluationType(propertyData.evaluation_type)}
                        </StyledInput>
                      </Grid>
                      <Grid item xs={4} xl={3}>
                        <InputTitle>{Constants.clientType}</InputTitle>
                        <StyledInput>
                          {propertyData &&
                            convertClientKind(propertyData.client_kind)}
                        </StyledInput>
                      </Grid>
                      <Grid item xs={4} xl={3}>
                        <SelectTextField
                          id="os-function"
                          label="finalidade"
                          value={propertyFunction}
                          setValue={setPropertyFunction}
                          selectOptions={selectReportFunction()}
                        />
                      </Grid>
                      <Grid item xs={4} xl={3}>
                        <SelectTextField
                          id="property-use"
                          label="uso do imóvel"
                          value={propertyUse}
                          setValue={setPropertyUse}
                          selectOptions={selectPropertyUse()}
                        />
                      </Grid>
                    </Grid>
                  </SectionBox>
                ) : (
                  <ClientData propertyData={propertyData} />
                )}
                <ToogleAccordion expand={expandAll} toogle={toogleAccordions} />
              </>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne &&
                  (enableEditing ? (
                    <>
                      <WorkOrderFile
                        downloadRegister={downloadRegister}
                        downloadIptu={downloadIptu}
                        registerFileName={registerFileName}
                        iptuFileName={iptuFileName}
                        handleFileUpload={handleFileUpload}
                      />
                      <Grid container spacing={4}>
                        <Grid item xs={4}>
                          <CustomTextField
                            required
                            id="register-number"
                            label="nº da matrícula"
                            value={registerNumber}
                            setValue={setRegisterNumber}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <InputTitle>tipo do imóvel:</InputTitle>
                          <StyledInput>
                            {propertyData &&
                              convertPropertyType(
                                propertyData.real_estate_kind
                              )}
                          </StyledInput>
                        </Grid>
                        <Grid item xs={4}>
                          <SelectTextField
                            id="concept"
                            label="conceito do espaço"
                            value={concept}
                            setValue={setConcept}
                            selectOptions={selectConcept()}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <NumericTextField
                            id="zone"
                            label="ofício/zona"
                            suffix=""
                            maxLength={12}
                            value={zone}
                            setValue={setZone}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <CustomTextField
                            required
                            id="judicial-district"
                            label="comarca"
                            value={judicialDistrict}
                            setValue={setJudicialDistrict}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <SelectTextField
                            id="registration-uf"
                            label="UF"
                            value={registrationUf}
                            setValue={setRegistrationUf}
                            selectOptions={selectRegistrationUf()}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <PropertyData propertyData={propertyData} />
                  ))}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo &&
                  (enableEditing ? (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <CustomPatternFormat
                              required
                              id="cep"
                              label="CEP"
                              value={cep}
                              setValue={setCep}
                              format="#####-###"
                              helper={verifyCep}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <CustomTextField
                              required
                              id="address"
                              label="logradouro"
                              value={address}
                              setValue={setAddress}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CustomTextField
                              required
                              id="address-number"
                              label="número"
                              value={addressNumber}
                              setValue={setAddressNumber}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <CustomTextField
                              id="complement"
                              label="complemento"
                              value={complement}
                              setValue={setComplement}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CustomTextField
                              required
                              id="district"
                              label="bairro"
                              value={district}
                              setValue={setDistrict}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CustomTextField
                              required
                              id="city"
                              label="cidade"
                              value={city}
                              setValue={setCity}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CustomTextField
                              required
                              id="uf"
                              label="estado"
                              value={uf}
                              setValue={setUf}
                              onChange={setIsAddressEdited}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <RoundedButton
                              onClick={() => {
                                setSearchMap(!searchMap);
                                setIsAddressEdited(false);
                              }}
                            >
                              {Constants.searchMap}
                            </RoundedButton>
                          </Grid>
                          <CoordGrid item xs={6}>
                            <BoldTypography>{Constants.lat}</BoldTypography>
                            <Typography>{pinPlace.lat}</Typography>
                          </CoordGrid>
                          <CoordGrid item xs={6}>
                            <BoldTypography>{Constants.lng}</BoldTypography>
                            <Typography>{pinPlace.lng}</Typography>
                          </CoordGrid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <StandardMap
                          searchMap={searchMap}
                          address={`${address} ${addressNumber} ${district} ${city}`}
                          pinPlace={pinPlace}
                          setPinPlace={setPinPlace}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <PropertyAddressWithMaps propertyData={propertyData} />
                  ))}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree &&
                  (enableEditing ? (
                    <Grid container spacing={4}>
                      <Grid item xs={2}>
                        <NumericTextField
                          id="total-area"
                          label={
                            propertyData?.real_estate_kind ===
                            PropertyType.APARTMENT
                              ? 'área total'
                              : 'área do terreno'
                          }
                          suffix=" m²"
                          decimalSeparator=","
                          decimalScale={2}
                          maxLength={18}
                          value={totalArea}
                          setValue={setTotalArea}
                        />
                      </Grid>
                      {propertyData?.real_estate_kind !== PropertyType.LOT && (
                        <>
                          <Grid item xs={2}>
                            <NumericTextField
                              id="built-area"
                              label={
                                propertyData?.real_estate_kind ===
                                PropertyType.APARTMENT
                                  ? 'área privativa'
                                  : 'área construída'
                              }
                              suffix=" m²"
                              decimalSeparator=","
                              decimalScale={2}
                              maxLength={18}
                              value={builtArea}
                              setValue={setBuiltArea}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <NumericTextField
                              id="rooms"
                              label="quartos"
                              suffix=" quartos"
                              maxLength={12}
                              value={propertyRooms}
                              setValue={setPropertyRooms}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <NumericTextField
                              id="toilets"
                              label="banheiros"
                              suffix=" banheiros"
                              maxLength={13}
                              value={toilets}
                              setValue={setToilets}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <NumericTextField
                              id="suites"
                              label="suítes"
                              suffix=" suítes"
                              maxLength={10}
                              value={suites}
                              setValue={setSuites}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <NumericTextField
                              id="parking-lots"
                              label="vagas de garagem"
                              suffix=" vagas"
                              maxLength={9}
                              value={parkingLots}
                              setValue={setParkingLots}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <NumericTextField
                              id="propertyAge"
                              label="idade do imóvel"
                              suffix=" anos"
                              maxLength={9}
                              value={age}
                              setValue={setAge}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <SelectTextField
                              id="conservation"
                              label="estado de conservação do imóvel"
                              value={conservation}
                              setValue={setConservation}
                              selectOptions={selectConservation()}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <SelectTextField
                              id="building-standard"
                              label="padrão construtivo"
                              value={buildingStandard}
                              setValue={setBuildingStandard}
                              selectOptions={selectStandard()}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  ) : (
                    <PropertyInfo propertyData={propertyData} />
                  ))}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete={completeScheduling} lastAccordion>
              <SectionBox id="scheduling-information">
                <AccordionTitle
                  title={Constants.schedulingInformations}
                  icon={IconCalendarTodayMS}
                  openAccordion={expandFour}
                  setOpenAccordion={setExpandFour}
                />
                {expandFour && (
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <StyledTextField
                        id={osId.toString()}
                        select
                        label={Constants.inspector}
                        color="secondary"
                        value={engineer}
                        onChange={(e) => {
                          getEngineerEvents(Number(e.target.value), today[0]);
                          setEngineer(Number(e.target.value));
                        }}
                        SelectProps={{
                          IconComponent: ExpandMoreIcon,
                        }}
                        sx={{
                          minWidth: '350px',
                          '& .MuiSvgIcon-root': {
                            fontSize: '2rem',
                          },
                        }}
                      >
                        <MenuItem disabled value={0}>
                          {Constants.selectInspector}
                        </MenuItem>
                        {usersData?.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </StyledTextField>
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={4}>
                      <StyledTextField
                        id="responsible"
                        color="secondary"
                        label={Constants.responsible}
                        value={responsibleName}
                        placeholder={Constants.placeholderResponsible}
                        onChange={(e) => {
                          setResponsibleName(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        id="cellphone"
                        label="contato"
                        color="secondary"
                        placeholder="Digite o contato"
                        inputProps={{ maxLength: 15 }}
                        value={contact}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setContact(formatPhone(e.target.value))
                        }
                        sx={PatternFormatStyle}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          label={Constants.date}
                          value={date}
                          format="DD-MM-YYYY"
                          slots={{
                            textField:
                              StyledTextField as React.ComponentType<TextFieldProps>,
                          }}
                          onChange={(e) => setDate(e)}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopTimePicker
                          label={Constants.beggining}
                          value={startTime}
                          ampm={false}
                          slots={{
                            textField:
                              StyledTextField as React.ComponentType<TextFieldProps>,
                          }}
                          onChange={handleStartTime}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopTimePicker
                          label={Constants.end}
                          value={endTime}
                          ampm={false}
                          slots={{
                            textField:
                              StyledTextField as React.ComponentType<TextFieldProps>,
                          }}
                          onChange={handleEndTime}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '16px' }}>
                      <RoundedButton
                        id="create-schedule-btn"
                        width="md"
                        onClick={() => handleSchedule(hasSchedule)}
                        sx={{ background: '#FAFCFF' }}
                      >
                        {hasSchedule
                          ? Constants.editSchedule
                          : Constants.createSchedule}
                      </RoundedButton>
                    </Grid>
                    {scheduledData && (
                      <Grid item xs={12} id="schedule-calendar">
                        <Calendar
                          deleteSchedule={handleDeleteSchedule}
                          eng={engineer}
                          scheduledData={scheduledData}
                          callback={getEngineerEvents}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <SubmitBox>
              <PeptDialog
                osId={osId}
                referenceNumber={propertyData?.reference_number}
              />
              {enableEditing ? (
                <FilledButton
                  form="schedule"
                  type="submit"
                  disabled={submitLoading}
                >
                  {submitLoading ? (
                    <CircularProgress size={22} />
                  ) : (
                    Constants.confirmEdit
                  )}
                </FilledButton>
              ) : (
                <ConfirmationDialog
                  id="approve-btn"
                  loading={loadingApprove}
                  title={Constants.approve}
                  text={Constants.changeStatus.replace(
                    '**',
                    `${propertyData?.reference_number}`
                  )}
                  modalCallback={() => {
                    setLoadingApprove(true);
                    handleScheduleExist();
                  }}
                />
              )}
            </SubmitBox>
            <Observations />
          </>
        )}
      </BoxContainer>
    </GridContainer>
  );
}
