/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';

import { StatusCode } from '../../../../../api/enumerations';
import { getNoticeAnnouncements } from '../../../../../api/hive/licensedCompanies';
import { AnnouncementData } from '../../../../../api/hive/licensedCompanies/types';
import {
  IconCloseFullscreenMS,
  IconOpenInFullMS,
} from '../../../../../constants/icons';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import useSnackbar from '../../../../../hooks/useSnackbar';
import { Constants } from '../../../constants';
import {
  presentAnnouncementData,
  presentAnnouncementsTableHead,
} from '../presenter';
import {
  Cell,
  ExpandButton,
  Row,
  StyledTable,
  StyledTableCell,
  StyledTableContainer,
  TableHeader,
} from '../styles';
import { AnnouncementDialog } from './AnnouncementDialog';

interface AnnoucementsTable {
  tableId: 'announcements' | 'newOs';
  expand: boolean;
  toggleTable: (tableId: 'announcements' | 'newOs') => void;
}

export function AnnouncementsTable({
  tableId,
  expand,
  toggleTable,
}: AnnoucementsTable): JSX.Element {
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [tableData, setTableData] = useState<AnnouncementData[]>([]);
  const [orderedData, setOrderedData] = useState<AnnouncementData[]>([]);
  const [openAnnouncementData, setOpenAnnouncementData] =
    useState<AnnouncementData>();

  const announcementsPerPage = 10;

  const observer = useRef<IntersectionObserver | null>(null);
  const { getErrorMessage } = useErrorMessage();
  const { handleSnackbar } = useSnackbar();

  const getAnnouncementsCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      setLoadingMore(false);
      setLoading(false);
      return;
    }

    try {
      const response = await getNoticeAnnouncements(page, announcementsPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setTotalPages(response.detail.total_pages);
      }

      if (response.data) {
        const visibleAnnouncements = response.data.filter(
          (announcement) => announcement.is_visible
        );
        setTableData([...tableData, ...visibleAnnouncements]);
        setLastCalledPage(page);
        setFirstLoad(false);
      }
    } catch (error) {
      handleSnackbar(getErrorMessage(error, true), true);
    } finally {
      setLoadingMore(false);
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    if (page !== 1) {
      getAnnouncementsCallback();
    }
  }, [page]);

  useEffect(() => {
    if (firstLoad) {
      setLoading(true);
      getAnnouncementsCallback();
    }
  }, [firstLoad]);

  const lastElementRef = (node: HTMLAnchorElement): void => {
    if (loading || loadingMore) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && page < totalPages) {
        setLoadingMore(true);
        setPage(page + 1);
      }
    });

    if (node) observer.current.observe(node);
  };

  const resetAnnouncementData = (): void => setOpenAnnouncementData(undefined);

  const sortByDate = (
    items: AnnouncementData[],
    order: 'asc' | 'desc' = 'desc'
  ): AnnouncementData[] => {
    return items.sort((a, b) => {
      const dateA = new Date(a.created_at).getTime();
      const dateB = new Date(b.created_at).getTime();
      if (order === 'asc') {
        return dateA - dateB;
      }
      return dateB - dateA;
    });
  };

  useEffect(() => {
    setOrderedData(sortByDate(tableData));
  }, [tableData]);

  return (
    <Box textAlign="center">
      <TableHeader>
        <Box width="18px" />
        {Constants.messages}
        <ExpandButton onClick={() => toggleTable(tableId)}>
          {expand ? IconCloseFullscreenMS : IconOpenInFullMS}
        </ExpandButton>
      </TableHeader>
      <StyledTableContainer>
        <StyledTable stickyHeader aria-label="sticky table">
          <TableHead>
            <Row className="table">
              {presentAnnouncementsTableHead().map((cell) => (
                <Cell
                  align="center"
                  key={cell.id}
                  width={cell.width && cell.width}
                >
                  {cell.label}
                </Cell>
              ))}
            </Row>
          </TableHead>
          <TableBody>
            {!loading && tableData.length === 0 ? (
              <TableRow>
                <StyledTableCell
                  align="center"
                  colSpan={7}
                  sx={{ borderBottom: 'none' }}
                >
                  {Constants.noAnnouncements}
                </StyledTableCell>
              </TableRow>
            ) : (
              (expand ? orderedData : orderedData.slice(0, 5)).map(
                (announcement, index) => {
                  const lastElement = orderedData.length === index + 1;
                  return (
                    <Box
                      component={Row}
                      key={announcement.id}
                      ref={lastElement && expand ? lastElementRef : undefined}
                      onClick={() => setOpenAnnouncementData(announcement)}
                    >
                      {presentAnnouncementData(announcement).map((cell) => (
                        <StyledTableCell key={cell.id} align="center">
                          {cell.value}
                        </StyledTableCell>
                      ))}
                    </Box>
                  );
                }
              )
            )}
            {(loading || (expand && loadingMore)) && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={7}
                  sx={{ borderBottom: 'none' }}
                >
                  <CircularProgress size={22} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      {openAnnouncementData && (
        <AnnouncementDialog
          announcementData={openAnnouncementData}
          open={!!openAnnouncementData}
          handleClose={resetAnnouncementData}
        />
      )}
    </Box>
  );
}
