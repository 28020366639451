/* eslint-disable react-hooks/exhaustive-deps */

import { Box, CircularProgress, Typography } from '@mui/material';
import { driver } from 'driver.js';
import { useEffect, useState } from 'react';

import { EvaluationType, StepTour } from '../../api/enumerations';
import { AttachFilesDialog } from '../../components/Dialog/AttachFilesDialog';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog';
import { MessagesDialog } from '../../components/Dialog/Messages';
import { ReportErrorDialog } from '../../components/Dialog/ReportErrorDialog';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { FormResponse } from '../../components/Sections/FormResponse';
import { Observations } from '../../components/Sections/Observations';
import { PdfViewer } from '../../components/Sections/PdfViewer';
import { WorkOrderProgressBar } from '../../components/Sections/ProgressBar';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { ReportInfo } from '../../components/Sections/ReportInfo';
import { Rooms } from '../../components/Sections/Rooms';
import { SampleSection } from '../../components/Sections/Sample';
import { ScheduleInfo } from '../../components/Sections/ScheduleInfo';
import { StatisticalReport } from '../../components/Sections/StatisticalReport';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/ToogleAccordion';
import {
  BoxContainer,
  ButtonsSpace,
  CancelOsBox,
  FlexSpaceBetweenBox,
  LoadingBox,
  SectionBox,
  SubmitBox,
} from '../../components/UI/Box';
import {
  BackButton,
  CancelOSButton,
  FilledButton,
} from '../../components/UI/Button';
import { GridContainer } from '../../components/UI/Grid';
import { WhiteCircularProgress } from '../../components/UI/Typography';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconCancelMS,
  IconDescriptionMS,
  IconEngineeringMS,
  IconFactCheckMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPhotoLibraryMS,
} from '../../constants/icons';
import { Constants } from '../../constants/revision';
import { driverConfig } from '../../helpers/driver/config';
import { revisionSteps } from '../../helpers/driver/steps';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useGeneral from '../../hooks/useGeneral';
import { useTour } from '../../hooks/useTour';
import { StyledTooltip } from '../SampleCreation/styles';
import useRevision from './hooks';
import { UploadFiles } from './UploadFiles';

export default function Revision(): JSX.Element {
  const [expandEleven, setExpandEleven] = useState(false);
  const [tourHasStarted, setTourHasStarted] = useState(false);
  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const { navigateHome, osId } = useGeneral();
  const {
    handleStatus,
    setLoadingApprove,
    loadingApprove,
    loadingPreviousStatus,
    handlePreviousStatus,
  } = useChangeStatus();
  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandEight,
    setExpandEight,
    expandNine,
    setExpandNine,
    expandAll,
    setExpandAll,
    toogleAccordions,
  } = useAccordion();
  const {
    propertyData,
    sampleData,
    hasAvmReport,
    rooms,
    openErrorReport,
    setOpenErrorReport,
    loadingPage,
    requestStatus,
    submitWorkOrder,
    submitWorkOrderLoading,
    selectedPictures,
    handleCalculationReport,
    handleStatisticalReport,
    submitLoadingFile,
  } = useRevision();

  const {
    tourCompletion,
    setTourCompletion,
    setTutorialStep,
    toBeContinued,
    setToBeContinued,
    isTourOn,
    setTourOn,
    tourSelection,
    setTourSelection,
    driveIsActive,
    setDriveIsActive,
    enableTour,
  } = useTour();

  const readOnly =
    requestStatus === 'worker_finalized' || requestStatus === 'hive_accepted';

  const hasManualFiles =
    propertyData?.task_id === null
      ? propertyData?.manual_statistical_report_report_pdf === null ||
        propertyData?.manual_statistical_report_calculation_report_document ===
          null
      : false;

  const driverObj = driver({
    ...driverConfig,
    steps: revisionSteps,
    onNextClick: () => {
      const activeIndex = driverObj.getActiveIndex();
      if (driverObj.isLastStep()) {
        setTourCompletion({
          ...tourCompletion,
          revision: { complete: true },
          lastStepSeen: StepTour.REVISIONFINISH,
        });
        setTutorialStep(StepTour.REVISIONFINISH);
        setToBeContinued(false);
        setTourSelection(false);
        setDriveIsActive(false);
        setTourOn(true);
      }
      if (!enableTour && activeIndex === 0) {
        driverObj.destroy();
      }
      driverObj.moveNext();
    },
    onCloseClick: () => {
      setDriveIsActive(false);
      setTourSelection(false);
      setToBeContinued(false);
      driverObj.destroy();
    },
    onDestroyStarted: () => {
      setDriveIsActive(false);
      setTourSelection(false);
      setToBeContinued(false);
      driverObj.destroy();
    },
  });

  useEffect(() => {
    if (
      expandOne &&
      expandTwo &&
      expandThree &&
      expandFour &&
      expandFive &&
      expandSix &&
      expandSeven &&
      expandEight &&
      expandNine
    ) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [
    expandOne,
    expandTwo,
    expandThree,
    expandFour,
    expandFive,
    expandSix,
    expandSeven,
    expandEight,
    expandNine,
  ]);

  useEffect(() => {
    setExpandNine(true);

    if (
      !tourCompletion.revision.complete &&
      !tourCompletion.skipTour.revision
    ) {
      setTutorialStep(StepTour.REVISIONSTART);
      if (!driveIsActive && !tourHasStarted) {
        setTourOn(true);
        setTourHasStarted(true);
      } else if (!isTourOn && driveIsActive && !loadingPage) {
        driverObj.drive();
      }
    } else if (tourSelection && driveIsActive && !isTourOn) {
      setTutorialStep(StepTour.REVISIONSTART);
      setTimeout(() => {
        driverObj.drive();
      }, 250);
    }
  }, [
    toBeContinued,
    tourSelection,
    driveIsActive,
    isTourOn,
    tourHasStarted,
    enableTour,
  ]);

  const showSchedulingAccordion =
    propertyData?.evaluation_type === EvaluationType.SIMPFACTORS ||
    propertyData?.evaluation_type === EvaluationType.SIMPINFERENCES;

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.revision}
          createdAt={propertyData?.created_at}
        />
        {loadingPage || !propertyData ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              {!isBank ? (
                <CancelOsBox>
                  <ConfirmationDialog
                    text={Constants.cancelOsText}
                    button={
                      <CancelOSButton>
                        {IconCancelMS}
                        {Constants.cancelOs}
                      </CancelOSButton>
                    }
                    model="error"
                    modalCallback={handleCancelWorkOrder}
                  />
                </CancelOsBox>
              ) : (
                <FlexSpaceBetweenBox />
              )}
              <FlexSpaceBetweenBox gap="10px">
                {isBank && propertyData?.worker_company_id !== null && (
                  <MessagesDialog />
                )}
                <AttachFilesDialog propertyData={propertyData} osId={osId} />
              </FlexSpaceBetweenBox>
            </FlexSpaceBetweenBox>
            <WorkOrderProgressBar complete>
              <>
                <ClientData propertyData={propertyData} />
                <ToogleAccordion expand={expandAll} toogle={toogleAccordions} />
              </>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne && <PropertyData propertyData={propertyData} />}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps
                    checkLocation
                    propertyData={propertyData}
                  />
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && <PropertyInfo propertyData={propertyData} />}
              </SectionBox>
            </WorkOrderProgressBar>
            {showSchedulingAccordion && (
              <WorkOrderProgressBar complete>
                <SectionBox>
                  <AccordionTitle
                    title={Constants.scheduleInfo}
                    icon={IconCalendarTodayMS}
                    openAccordion={expandFour}
                    setOpenAccordion={setExpandFour}
                  />
                  {expandFour && (
                    <ScheduleInfo
                      inspectionData={propertyData.inspection}
                      rooms={rooms}
                    />
                  )}
                </SectionBox>
              </WorkOrderProgressBar>
            )}
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyPhotos}
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandFive}
                  setOpenAccordion={setExpandFive}
                />
                {expandFive && (
                  <Box>
                    {propertyData?.inspection && rooms.length > 0 ? (
                      <Rooms
                        navigationPath={`${osId}/inspection/${propertyData.inspection.id}/rooms`}
                        rooms={rooms}
                        osId={osId}
                        inspectionId={propertyData.inspection.id}
                      />
                    ) : (
                      <Typography>{Constants.noPhotos}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.form}
                  icon={IconListAltMS}
                  openAccordion={expandSix}
                  setOpenAccordion={setExpandSix}
                />
                {expandSix && (
                  <FormResponse
                    questionForm={propertyData?.inspection?.form_response}
                  />
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.sample}
                  icon={IconFactCheckMS}
                  openAccordion={expandSeven}
                  setOpenAccordion={setExpandSeven}
                />
                {expandSeven && (
                  <Box>
                    {sampleData && sampleData?.length > 0 ? (
                      <SampleSection
                        sampleData={sampleData}
                        propertyData={propertyData}
                      />
                    ) : (
                      <Typography>{Constants.noSample}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.calculation}
                  icon={IconEngineeringMS}
                  openAccordion={expandEight}
                  setOpenAccordion={setExpandEight}
                />
                {expandEight && (
                  <Box>
                    {hasAvmReport && propertyData ? (
                      <ReportInfo propertyData={propertyData} />
                    ) : (
                      <Box>{Constants.noReport}</Box>
                    )}
                  </Box>
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar
              complete={propertyData.manual_statistical_report !== null}
            >
              <SectionBox id="statistical-report">
                <AccordionTitle
                  title="Relatório estatístico"
                  icon={IconDescriptionMS}
                  openAccordion={expandEleven}
                  setOpenAccordion={setExpandEleven}
                />
                {expandEleven && (
                  <StatisticalReport
                    propertyData={propertyData}
                    hasPictures={rooms.length > 0}
                    selectedPictures={selectedPictures}
                    readOnly
                  />
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar
              complete={
                propertyData.task_id === null
                  ? propertyData.manual_statistical_report_report_pdf !==
                      null &&
                    propertyData.manual_statistical_report_calculation_report_document !==
                      null
                  : true
              }
              lastAccordion
            >
              <SectionBox id="pdf-viewer">
                <AccordionTitle
                  title={Constants.report}
                  icon={IconDescriptionMS}
                  openAccordion={expandNine}
                  setOpenAccordion={setExpandNine}
                />
                {expandNine &&
                  (propertyData.task_id === null ? (
                    <Box display="flex" gap="21px" width="600px">
                      <UploadFiles
                        fileType="report"
                        file={propertyData.manual_statistical_report_report_pdf}
                        submitCallback={handleStatisticalReport}
                        loading={submitLoadingFile}
                        readOnly={readOnly}
                      />
                      <UploadFiles
                        fileType="memorial"
                        file={
                          propertyData.manual_statistical_report_calculation_report_document
                        }
                        submitCallback={handleCalculationReport}
                        loading={submitLoadingFile}
                        readOnly={readOnly}
                      />
                    </Box>
                  ) : (
                    <PdfViewer
                      osId={osId}
                      samplePdf={propertyData?.samples_report_pdf}
                    />
                  ))}
              </SectionBox>
            </WorkOrderProgressBar>
            <SubmitBox>
              {!readOnly ? (
                <ConfirmationDialog
                  text={Constants.backStatusText.replace(
                    '**',
                    `${propertyData?.reference_number}`
                  )}
                  button={
                    <FilledButton
                      model="warning"
                      disabled={loadingPreviousStatus}
                    >
                      {loadingPreviousStatus ? (
                        <WhiteCircularProgress size={22} />
                      ) : (
                        Constants.previousStatus
                      )}
                    </FilledButton>
                  }
                  modalCallback={() =>
                    handlePreviousStatus(
                      osId,
                      propertyData?.reference_number,
                      Constants.previousStatusName
                    )
                  }
                />
              ) : (
                <Box />
              )}
              <ButtonsSpace>
                {!(isBank && propertyData.worker_company_id !== null) && (
                  <>
                    <FilledButton
                      id="reject-button"
                      onClick={() => setOpenErrorReport(true)}
                    >
                      {Constants.errorReport}
                    </FilledButton>
                    <ReportErrorDialog
                      open={openErrorReport}
                      setOpen={setOpenErrorReport}
                    />
                  </>
                )}
                {isBank &&
                  propertyData.worker_company_id !== null &&
                  (requestStatus === 'in_progress' ||
                    requestStatus === 'hive_rejected') && (
                    <ConfirmationDialog
                      text={Constants.finishWorkOrder.replace(
                        '**',
                        `${propertyData?.reference_number}`
                      )}
                      button={
                        <StyledTooltip
                          placement="top"
                          title={Constants.manualFiles}
                          disableHoverListener={!hasManualFiles}
                        >
                          <span style={{ width: '100%' }}>
                            <FilledButton
                              width="md"
                              disabled={
                                hasManualFiles || submitWorkOrderLoading
                              }
                            >
                              {submitWorkOrderLoading ? (
                                <WhiteCircularProgress size={22} />
                              ) : (
                                Constants.finishAndSend
                              )}
                            </FilledButton>
                          </span>
                        </StyledTooltip>
                      }
                      modalCallback={submitWorkOrder}
                    />
                  )}
                {!(isBank && propertyData.worker_company_id !== null) && (
                  <ConfirmationDialog
                    id="approve-btn"
                    text={Constants.changeStatus.replace(
                      '**',
                      `${propertyData?.reference_number}`
                    )}
                    button={
                      <StyledTooltip
                        placement="top"
                        title={Constants.manualFiles}
                        disableHoverListener={!hasManualFiles}
                      >
                        <span style={{ width: '100%' }}>
                          <FilledButton
                            width="md"
                            disabled={hasManualFiles || loadingApprove}
                          >
                            {loadingApprove ? (
                              <WhiteCircularProgress size={22} />
                            ) : (
                              Constants.approve
                            )}
                          </FilledButton>
                        </span>
                      </StyledTooltip>
                    }
                    modalCallback={() => {
                      setLoadingApprove(true);
                      handleStatus(osId, propertyData?.reference_number);
                    }}
                  />
                )}
              </ButtonsSpace>
            </SubmitBox>
            <Observations />
          </>
        )}
      </BoxContainer>
    </GridContainer>
  );
}
